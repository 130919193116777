import { Box, ResponsiveContext, Text } from "grommet";
import { useCallback, useContext, useEffect, useState } from "react";

import { USDCContract, PublicSaleABI } from "../../contracts";
import { useWeb3Context } from "../../hooks/web3-context";
import { ethers } from "ethers";
import Addresses from "../../constants/addresses";
import {
  PRE_SALE_ZEMPYR_PRICE,
  PRE_SALE_ZEMPYR_SUPPLY,
  PUBLIC_SALE_LIMIT,
} from "../../constants/decimals";
import TotalRaisedView from "./TotalRaisedView";
// import ConnectWallet from "./ConnectWallet";
// import { Wrapper } from "..";
// import IDOView from "./IDOView";

const Offering = () => {
  // const { address: wallet, connect, connected, provider } = useWeb3Context();
  const { address: wallet, connected, provider } = useWeb3Context();
  const usdc = new ethers.Contract(Addresses.USDC, USDCContract.abi, provider);
  // const zEmpyr = new ethers.Contract(
  //   Addresses.zEMPYR,
  //   zEMPYRContract.abi,
  //   provider
  // );
  const publicSale = new ethers.Contract(
    Addresses.PublicSale,
    PublicSaleABI,
    provider
  );

  const [totalSaleUSDC, setTotalSaleUSDC] = useState(null);

  /**
   possible states:
   - loading
   - loaded
   - approving
   - approved
   - purchasing
   - purchased
   - error
   */
  const initialData = {
    state: "loading",
    approved: null,
    whitelisted: null,
    depositLimit: null,
    totalSaleUSDC: null,
    error: null,
  };
  const [data, setData] = useState(initialData);

  const loadData = useCallback(
    async (finalState = "loaded") => {
      if (connected) {
        let usdcDecimals = await usdc.decimals();

        setData({
          ...data,
          state: "loading",
        });

        // load wallet balance
        let walletUSDCBalance = await usdc.balanceOf(wallet);
        walletUSDCBalance = ethers.utils.formatUnits(
          walletUSDCBalance,
          usdcDecimals
        );

        // get allowance
        let allowance = await usdc.allowance(wallet, Addresses.PublicSale);
        let approved = !!allowance.gte(
          ethers.utils.parseUnits("1000", usdcDecimals)
        );

        // load whitelist deposit limit
        let depositLimit = ethers.utils
          .parseUnits(PUBLIC_SALE_LIMIT, usdcDecimals)
          .sub(await publicSale._totalUserDeposit(wallet));

        // check if whitelisted
        // let whitelisted = false;
        // let walletPSUSBalance = await zEmpyr.balanceOf(wallet);
        // if (depositLimit.gt("0") || walletPSUSBalance.gt("0"))
        //   whitelisted = true;
        let whitelisted = true;

        depositLimit = ethers.utils.formatUnits(depositLimit, usdcDecimals);

        setData({
          ...initialData,
          state: finalState,
          walletUSDCBalance,
          approved,
          whitelisted,
          depositLimit,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallet, provider, connected]
  );

  // const approve = async () => {
  //   try {
  //     setData({
  //       ...data,
  //       state: "approving",
  //     });
  //     const tx = await usdc
  //       .connect(provider.getSigner())
  //       .approve(Addresses.PublicSale, constants.MaxUint256);
  //     await tx.wait(BLOCK_CONFIRMATIONS);
  //     loadData("approved");
  //   } catch (error) {
  //     console.error(error);
  //     setData({
  //       ...data,
  //       state: "error",
  //       error: error.message,
  //     });
  //   }
  // };

  // const purchase = async (amount) => {
  //   try {
  //     setData({
  //       ...data,
  //       state: "purchasing",
  //     });
  //     const tx = await publicSale
  //       .connect(provider.getSigner())
  //       .deposit(ethers.utils.parseUnits(amount, await usdc.decimals()));
  //     await tx.wait(BLOCK_CONFIRMATIONS);
  //     loadData("purchased");
  //   } catch (error) {
  //     console.error(error);
  //     setData({
  //       ...data,
  //       state: "error",
  //       error: error.message,
  //     });
  //   }
  // };

  // load data when wallet is connected
  useEffect(
    () => {
      const _ = async () => {
        await loadData();
      };
      _();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [connected]
  );

  // load total sale amount when state changes
  useEffect(
    () => {
      const _ = async () => {
        let totalSaleUSDC;
        // let zEmpyrDecimals = await zEmpyr.decimals();
        let usdcDecimals = await usdc.decimals();
        //
        // let zEmpyrIdoPrice = ethers.utils.formatUnits(
        //   await whitelistSale._mintTokenPrice(),
        //   usdcDecimals
        // );
        //
        // // get total sale amount
        // totalSaleUSDC = (await zEmpyr.totalSupply()).mul(
        //   parseFloat(zEmpyrIdoPrice)
        // ); // in zEMPYR decimals
        // totalSaleUSDC = ethers.utils.formatUnits(totalSaleUSDC, zEmpyrDecimals);

        let totalGlobalDeposit = await publicSale._totalGlobalDeposit();
        totalSaleUSDC = ethers.utils.formatUnits(
          ethers.utils
            .parseUnits(PRE_SALE_ZEMPYR_SUPPLY)
            .mul(PRE_SALE_ZEMPYR_PRICE)
            .toString()
        );
        totalSaleUSDC = ethers.utils
          .parseUnits(totalSaleUSDC, usdcDecimals)
          .add(totalGlobalDeposit);

        setTotalSaleUSDC(ethers.utils.formatUnits(totalSaleUSDC, usdcDecimals));
      };
      _();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.state]
  );

  const size = useContext(ResponsiveContext);
  const sizeVariables = {
    headingFontSize: {
      small: "xlarge",
      medium: "46px",
      large: "46px",
    },
    subheadingFontSize: {
      small: "medium",
      medium: "large",
      large: "xlarge",
    },
    textGap: {
      small: "medium",
      medium: "small",
      large: "small",
    },
  };

  return (
    <Box direction="row" justify="center">
      <Box gap="medium" width="800px">
        <Box gap={sizeVariables.textGap[size]} align="center">
          <Text
            size={sizeVariables.headingFontSize[size]}
            weight={500}
            textAlign="center"
          >
            $EMPYR Offering
          </Text>
          <Text
            size={sizeVariables.subheadingFontSize[size]}
            textAlign="center"
          >
            The Public sale has ended! Thank you for participating! 💫
          </Text>
        </Box>
        <TotalRaisedView totalSaleUSDC={totalSaleUSDC} />

        {/*{!connected ? (*/}
        {/*  <ConnectWallet connect={connect} />*/}
        {/*) : data.state === "loading" ? (*/}
        {/*  <Wrapper>*/}
        {/*    <Spinner size="medium" color="white" alignSelf="center" />*/}
        {/*  </Wrapper>*/}
        {/*) : (*/}
        {/*  <IDOView approve={approve} purchase={purchase} {...data} />*/}
        {/*)}*/}
      </Box>
    </Box>
  );
};

export default Offering;
