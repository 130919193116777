import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Grommet } from "grommet";

import "./styles.css";
import theme from "./theme";
import {
  Home,
  AirdropEligibility,
  Claim,
  Layout,
  Offering,
} from "./components";
import { Web3ContextProvider } from "./hooks/web3-context";

const CELESTIA_HOST = "celestia.fi";
const EMPYREAN_HREF = "https://empyrean.fi/";

const App = () => {
  useEffect(() => {
    let currentUrl = new URL(window.location.href);
    if (currentUrl.host === CELESTIA_HOST) window.location.href = EMPYREAN_HREF;
  }, []);

  return (
    <Web3ContextProvider>
      <Grommet theme={theme}>
        <Layout>
          <Routes>
            <Route path="airdrop" element={<AirdropEligibility />} />
            <Route path="offering" element={<Offering />} />
            <Route path="claim" element={<Claim />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Layout>
      </Grommet>
    </Web3ContextProvider>
  );
};

export default App;
