import { useContext } from "react";
import { Box, ResponsiveContext } from "grommet";

const Container = ({ children }) => {
  const size = useContext(ResponsiveContext);

  const sizeVariables = {
    horizontalPadding: {
      small: "large",
      medium: "large",
      large: "xlarge",
    },
  };

  return (
    <Box
      width="100%"
      pad={{ horizontal: sizeVariables.horizontalPadding[size] }}
    >
      {children}
    </Box>
  );
};

export default Container;
