export const BLOCK_CONFIRMATIONS = 1;

export const PRE_SALE_ZEMPYR_SUPPLY = "69433.319580000";

export const PRE_SALE_ZEMPYR_PRICE = 10;

export const PUBLIC_SALE_ZEMPYR_PRICE = 27.15;

export const PUBLIC_SALE_LIMIT = "10000";

export const LAUNCH_TIME = new Date(Date.UTC(2022, 0, 6, 16, 30));
