import { useContext } from "react";
import { Box, Image, ResponsiveContext } from "grommet";

import bg from "../images/bg/sky_bg_muted.png";
import planet from "../images/bg/planet_purple.png";
import { Container, Nav } from ".";

const Layout = ({ children }) => {
  const size = useContext(ResponsiveContext);

  const sizeVariables = {
    planetROffset: {
      small: "-30%",
      medium: "-15%",
      large: "0",
    },
    planetOpacity: {
      small: "0.5",
      medium: "1",
      large: "1",
    },
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      background={{
        color: "black",
        image: `url(${bg})`,
      }}
      overflow="hidden"
      style={{
        position: "relative",
      }}
    >
      <Nav />

      <Image
        src={planet}
        style={{
          position: "absolute",
          height: "100%",
          right: sizeVariables.planetROffset[size],
          opacity: sizeVariables.planetOpacity[size],
        }}
      />

      <Box
        height="100%"
        justify="center"
        style={{
          zIndex: "1",
        }}
      >
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};

export default Layout;
