import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  Header,
  Image,
  Anchor as GrommetAnchor,
  ResponsiveContext,
} from "grommet";

import logo from "../images/brand/empyrean_wordmark.svg";
import twitter from "../images/socials/twitter.svg";
import discord from "../images/socials/discord.svg";
import medium from "../images/socials/medium.svg";
import { Container } from ".";

const Anchor = styled(GrommetAnchor)`
  color: white;
  font-weight: 400;
`;

const Brand = () => {
  const size = useContext(ResponsiveContext);

  const sizeVariables = {
    height: {
      small: "30px",
      medium: "50px",
      large: "50px",
    },
  };

  return (
    <Box>
      <Image src={logo} height={sizeVariables.height[size]} />
    </Box>
  );
};

const SocialLink = ({ icon, link }) => (
  <Box>
    <Anchor
      size="small"
      href={link}
      label={
        <Box height="100%" justify="center">
          <Image src={icon} height="100%" />
        </Box>
      }
      target={link === "#" ? "_self" : "_blank"}
    />
  </Box>
);

const SocialLinks = () => {
  const socials = [
    {
      icon: twitter,
      name: "Twitter",
      link: "https://twitter.com/empyreanfi",
    },
    {
      icon: discord,
      name: "Discord",
      link: "https://discord.gg/empyreandao",
    },
    {
      icon: medium,
      name: "Medium",
      link: "https://empyreanfi.medium.com",
    },
  ];

  return (
    <Box direction="row" gap="large">
      {socials.map((social, i) => (
        <SocialLink key={i} {...social} />
      ))}
    </Box>
  );
};

const Nav = () => (
  <Container>
    <Header
      pad={{
        vertical: "medium",
      }}
      style={{
        zIndex: "100",
      }}
    >
      <Link to="/">
        <Brand />
      </Link>
      <SocialLinks />
    </Header>
  </Container>
);

export default Nav;
