import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, ResponsiveContext, Spinner, Text } from "grommet";

// import AirdropEligibilityForm from "./AirdropEligibilityForm";
import { useWeb3Context } from "../../hooks/web3-context";
import { ethers } from "ethers";
import Addresses from "../../constants/addresses";
import { ClaimAirdropContract, ClaimAirdropV2Contract } from "../../contracts";
import { BLOCK_CONFIRMATIONS } from "../../constants/decimals";
import { ConnectWallet, Wrapper } from "../index";

const AirdropEligibility = () => {
  const { address: wallet, connect, connected, provider } = useWeb3Context();
  const claimAirdrop = new ethers.Contract(
    Addresses.ClaimAirdrop,
    ClaimAirdropContract.abi,
    provider
  );
  const claimAirdropV2 = new ethers.Contract(
    Addresses.ClaimAirdropV2,
    ClaimAirdropV2Contract.abi,
    provider
  );

  const initialData = {
    state: "loading",
    whitelisted: null,
    claimed: null,
    error: null,
  };
  const [data, setData] = useState(initialData);

  const loadData = useCallback(
    async (finalState = "loaded") => {
      if (connected) {
        const whitelisted = await claimAirdrop._isWhitelisted(wallet);
        const claimed = await claimAirdrop._hasClaimed(wallet);

        setData({
          ...initialData,
          state: finalState,
          whitelisted,
          claimed,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallet, provider, connected]
  );

  const claim = async () => {
    try {
      setData({
        ...data,
        state: "claiming",
      });
      const tx = await claimAirdropV2.connect(provider.getSigner()).claim();
      await tx.wait(BLOCK_CONFIRMATIONS);
      loadData("claimed");
    } catch (error) {
      console.error(error);
      setData({
        ...data,
        state: "error",
        error: error.message,
      });
    }
  };

  // load data when wallet is connected
  useEffect(
    () => {
      const _ = async () => {
        await loadData();
      };
      _();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [connected]
  );

  const size = useContext(ResponsiveContext);
  const sizeVariables = {
    headingFontSize: {
      small: "xlarge",
      medium: "46px",
      large: "46px",
    },
    subheadingFontSize: {
      small: "medium",
      medium: "large",
      large: "xlarge",
    },
    textGap: {
      small: "medium",
      medium: "small",
      large: "small",
    },
  };

  return (
    <Box direction="row" justify="center">
      <Box gap="medium" width="800px">
        <Box gap={sizeVariables.textGap[size]} align="center">
          <Text
            size={sizeVariables.headingFontSize[size]}
            weight={500}
            textAlign="center"
          >
            $EMPYR Airdrop
          </Text>
          <Text
            size={sizeVariables.subheadingFontSize[size]}
            textAlign="center"
          >
            Early Trisolaris users are eligible for this airdrop. 💫
          </Text>
        </Box>
        {/*<AirdropEligibilityForm />*/}

        {!connected ? (
          <ConnectWallet connect={connect} />
        ) : data.state === "loading" ? (
          <Wrapper>
            <Spinner size="medium" color="white" alignSelf="center" />
          </Wrapper>
        ) : (
          <AirdropClaim claim={claim} {...data} />
        )}
      </Box>
    </Box>
  );
};

const AirdropClaim = ({ claim, state, whitelisted, claimed, error }) => {
  return (
    <Wrapper>
      {!whitelisted ? (
        <Text textAlign="center">
          Sorry, you are not eligible for the airdrop!
        </Text>
      ) : claimed || state === "claimed" ? (
        <Text textAlign="center">You have already claimed!</Text>
      ) : (
        <Box gap="medium" align="center">
          <Button
            primary
            disabled={state === "claiming"}
            label={
              state === "claiming" ? (
                <Spinner size="xsmall" color="dark-3" />
              ) : (
                "Claim"
              )
            }
            color="white"
            size="small"
            onClick={claim}
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default AirdropEligibility;
