import { Box, Button, ResponsiveContext, Spinner, Text } from "grommet";
import { useWeb3Context } from "../hooks/web3-context";
import { ConnectWallet, Wrapper } from "./index";
import { useCallback, useContext, useEffect, useState } from "react";
import { constants, ethers } from "ethers";
import Addresses from "../constants/addresses";
import { BLOCK_CONFIRMATIONS } from "../constants/decimals";
import { ClaimEmpyrContract, zEMPYRContract } from "../contracts";

const Claim = () => {
  const { address: wallet, connect, connected, provider } = useWeb3Context();

  const zEmpyr = new ethers.Contract(
    Addresses.zEMPYR,
    zEMPYRContract.abi,
    provider
  );
  const claimEmpyr = new ethers.Contract(
    Addresses.ClaimEmpyr,
    ClaimEmpyrContract.abi,
    provider
  );

  const initialData = {
    state: "loading",
    walletBalance: null,
    approved: null,
    error: null,
  };
  const [data, setData] = useState(initialData);

  const loadData = useCallback(
    async (finalState = "loaded") => {
      if (connected) {
        let zEmpyrDecimals = await zEmpyr.decimals();

        setData({
          ...data,
          state: "loading",
        });

        // get zEmpyr balance
        let walletBalance = await zEmpyr.balanceOf(wallet);

        // get allowance
        let allowance = await zEmpyr.allowance(wallet, Addresses.ClaimEmpyr);
        let approved = !!allowance.gte(walletBalance);

        walletBalance = ethers.utils.formatUnits(walletBalance, zEmpyrDecimals);

        setData({
          ...initialData,
          state: finalState,
          walletBalance,
          approved,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [wallet, provider, connected]
  );

  const approve = async () => {
    try {
      setData({
        ...data,
        state: "approving",
      });
      const tx = await zEmpyr
        .connect(provider.getSigner())
        .approve(Addresses.ClaimEmpyr, constants.MaxUint256);
      await tx.wait(BLOCK_CONFIRMATIONS);
      loadData("approved");
    } catch (error) {
      console.error(error);
      setData({
        ...data,
        state: "error",
        error: error.message,
      });
    }
  };

  const claim = async () => {
    try {
      setData({
        ...data,
        state: "claiming",
      });
      const tx = await claimEmpyr.connect(provider.getSigner()).claim();
      await tx.wait(BLOCK_CONFIRMATIONS);
      loadData("claimed");
    } catch (error) {
      console.error(error);
      setData({
        ...data,
        state: "error",
        error: error.message,
      });
    }
  };

  useEffect(
    () => {
      const _ = async () => {
        await loadData();
      };
      _();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [connected]
  );

  const size = useContext(ResponsiveContext);
  const sizeVariables = {
    headingFontSize: {
      small: "xlarge",
      medium: "46px",
      large: "46px",
    },
    subheadingFontSize: {
      small: "medium",
      medium: "large",
      large: "xlarge",
    },
    textGap: {
      small: "medium",
      medium: "small",
      large: "small",
    },
  };

  return (
    <Box direction="row" justify="center">
      <Box gap="medium" width="800px">
        <Box gap={sizeVariables.textGap[size]} align="center">
          <Text
            size={sizeVariables.headingFontSize[size]}
            weight={500}
            textAlign="center"
          >
            Claim $EMPYR
          </Text>
          <Text
            size={sizeVariables.subheadingFontSize[size]}
            textAlign="center"
          >
            Claim your EMPYR by burning zEMPYR at a 1:1 ratio.
          </Text>
        </Box>
        {!connected ? (
          <ConnectWallet connect={connect} />
        ) : data.state === "loading" ? (
          <Wrapper>
            <Spinner size="medium" color="white" alignSelf="center" />
          </Wrapper>
        ) : (
          <ClaimView approve={approve} claim={claim} {...data} />
        )}
      </Box>
    </Box>
  );
};

const ClaimView = ({
  state,
  walletBalance,
  approved,
  error,
  approve,
  claim,
}) => {
  return (
    <Wrapper>
      {Number(walletBalance) === 0 ? (
        <Text textAlign="center">Sorry, you do not own any zEMPYR!</Text>
      ) : state === "claimed" ? (
        <Text textAlign="center">You have successfully claimed EMPYR! 💫</Text>
      ) : (
        <Box gap="medium" align="center">
          <Box gap="xxsmall" width="100%">
            <Box direction="row" justify="between" gap="small">
              <Text size="small">zEMPYR Balance:</Text>
              <Text size="small">{Number(walletBalance).toLocaleString()}</Text>
            </Box>
            <Box direction="row" justify="between" gap="small">
              <Text size="small">Claimable EMPYR:</Text>
              <Text size="small">{Number(walletBalance).toLocaleString()}</Text>
            </Box>
          </Box>
          <Box direction="row" gap="small">
            {!approved && (
              <Button
                disabled={state === "approving"}
                label={
                  state === "approving" ? (
                    <Spinner size="small" color="dark-3" />
                  ) : (
                    <Text size="small" color="white">
                      Approve
                    </Text>
                  )
                }
                color="white"
                size="small"
                onClick={approve}
              />
            )}
            <Button
              primary
              disabled={!approved || state === "claiming"}
              label={
                state === "claiming" ? (
                  <Spinner size="xsmall" color="dark-3" />
                ) : (
                  "Claim"
                )
              }
              color="white"
              size="small"
              onClick={() => {
                claim();
              }}
            />
          </Box>
          {state === "error" && (
            <Text size="small" color="red" textAlign="center">
              {error}
            </Text>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

export default Claim;
