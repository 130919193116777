import { DEFAULT_NETWORK, NETWORKS } from "./blockchain";

const ADDRESSES = Object.freeze({
  [NETWORKS.AURORA]: {
    USDC: "0xB12BFcA5A55806AaF64E99521918A4bf0fC40802",
    zEMPYR: "0x6055B9AD6589834Cb0cf19cB1AA1a204A32f0c0B",
    PublicSale: "0xb542d96F93D2eF11730e937326eBcF56E70e039B",
    ClaimEmpyr: "0x237ff56668963451614dcceEBC40BDC5FF0E5783",
    ClaimAirdrop: "0x3C1eEE1eaDC9bb2a526859fFE9F3BFa03b429815",
    ClaimAirdropV2: "0xCe25aE34d932E3240d41Bb0B1E52a28054E7EC39",
  },
});

export default ADDRESSES[DEFAULT_NETWORK];
