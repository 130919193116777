import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  createContext,
} from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { DEFAULT_NETWORK, NETWORKS } from "../constants/blockchain";
import { getMainnetURI } from "../helpers";

const Web3Context = createContext(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, " +
        "please declare it at a higher level."
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo(
    () => {
      return { ...onChainProvider };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3Context]
  );
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};

export const Web3ContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [chainID, setChainID] = useState(DEFAULT_NETWORK);
  const [address, setAddress] = useState("");

  const [uri, setUri] = useState(getMainnetURI());
  const [provider, setProvider] = useState(
    new ethers.providers.StaticJsonRpcProvider(uri)
  );

  const [web3Modal] = useState(
    new Web3Modal({
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [NETWORKS.AURORA]: uri,
            },
          },
        },
      },
      theme: "light",
    })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasCachedProvider = () => {
    if (!web3Modal) return false;
    if (!web3Modal.cachedProvider) return false;
    return true;
  };

  const _initListeners = useCallback(
    (rawProvider) => {
      if (!rawProvider.on) {
        return;
      }

      rawProvider.on("accountsChanged", () =>
        setTimeout(() => window.location.reload(), 1)
      );

      rawProvider.on("chainChanged", (chain) => {
        _checkNetwork(chain);
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("network", (_newNetwork, oldNetwork) => {
        if (!oldNetwork) return;
        window.location.reload();
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider]
  );

  const _checkNetwork = (otherChainID) => {
    if (Number(otherChainID) !== DEFAULT_NETWORK) {
      alert("Please switch your wallet to Aurora network to use Empyrean!");
    }

    if (chainID !== otherChainID) {
      console.warn("You are switching networks: ", otherChainID);
      if (otherChainID === NETWORKS.AURORA) {
        setChainID(otherChainID);
        setUri(getMainnetURI());
        return true;
      }
      return false;
    }
    return true;
  };

  const connect = useCallback(
    async () => {
      const rawProvider = await web3Modal.connect();

      _initListeners(rawProvider);

      const connectedProvider = new ethers.providers.Web3Provider(
        rawProvider,
        "any"
      );

      const chainId = await connectedProvider
        .getNetwork()
        .then((network) => network.chainId);
      const connectedAddress = await connectedProvider.getSigner().getAddress();

      const validNetwork = _checkNetwork(chainId);
      if (!validNetwork) {
        console.error("Wrong network, please switch to Cronos Network");
        return;
      }
      setAddress(connectedAddress);
      setProvider(connectedProvider);

      setConnected(true);

      return connectedProvider;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, web3Modal, connected]
  );

  const disconnect = useCallback(
    async () => {
      console.log("disconnecting");
      web3Modal.clearCachedProvider();
      setConnected(false);

      setTimeout(() => {
        window.location.reload();
      }, 1);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, web3Modal, connected]
  );

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
    }),
    [
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
    ]
  );
  return (
    <Web3Context.Provider value={{ onChainProvider }}>
      {children}
    </Web3Context.Provider>
  );
};
