import { Box, Button } from "grommet";

import { Wrapper } from "./index";

const ConnectWallet = ({ connect }) => {
  return (
    <Wrapper>
      <Box align="center">
        <Button
          primary
          size="small"
          color="white"
          label="Connect Wallet"
          onClick={() => {
            connect();
          }}
        />
      </Box>
    </Wrapper>
  );
};

export default ConnectWallet;
