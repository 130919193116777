import { useContext } from "react";
import styled from "styled-components";
import { Box, Button, Text, ResponsiveContext } from "grommet";
import { Link } from "react-router-dom";

const StyledButton = styled(Button)`
  ${(props) => (props?.primary ? `color: black;` : `color: white;`)}
  border-radius: 5px;
  padding: 8px 26px;
`;

const Home = () => {
  const size = useContext(ResponsiveContext);

  const sizeVariables = {
    headingFontSize: {
      small: "xlarge",
      medium: "48px",
      large: "64px",
    },
    headingLineHeight: {
      small: "36px",
      medium: "52px",
      large: "84px",
    },
    subheadingFontSize: {
      small: "small",
      medium: "large",
      large: "xlarge",
    },
    subheadingBoxWidth: {
      small: "100%",
      medium: "60%",
      large: "45%",
    },
    textGap: {
      small: "large",
      medium: "large",
      large: "large",
    },
    buttonGap: {
      small: "72px",
      medium: "large",
      large: "large",
    },
    buttonSize: {
      small: "small",
      medium: "medium",
      large: "medium",
    },
    ctaFontSize: {
      small: "xsmall",
      medium: "small",
      large: "small",
    },
    ctaProps: {
      small: { direction: "column", gap: "small" },
      medium: { direction: "row", align: "center", gap: "medium" },
      large: { direction: "row", align: "center", gap: "medium" },
    },
  };

  return (
    <Box gap={sizeVariables.buttonGap[size]}>
      <Box gap={sizeVariables.textGap[size]}>
        <Text
          size={sizeVariables.headingFontSize[size]}
          weight={700}
          style={{ lineHeight: sizeVariables.headingLineHeight[size] }}
        >
          The Decentralized
          <br />
          Reserve Currency
          <br />
          on Aurora
        </Text>
        <Box width={sizeVariables.subheadingBoxWidth[size]}>
          <Text size={sizeVariables.subheadingFontSize[size]} weight={400}>
            A financial instrument to grow your wealth - stake and earn
            compounding interest
          </Text>
        </Box>
      </Box>
      <Box {...sizeVariables.ctaProps[size]}>
        <a
          href="https://app.empyrean.fi"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          <StyledButton
            primary
            color="white"
            label="Enter Empyrean"
            alignSelf="start"
            size={sizeVariables.buttonSize[size]}
          />
        </a>

        <Link to="/airdrop" style={{ color: "white" }}>
          <StyledButton
            color="white"
            label="Claim Airdrop"
            alignSelf="start"
            size={sizeVariables.buttonSize[size]}
          />
        </Link>

        {/*<StyledButton*/}
        {/*  color="white"*/}
        {/*  label={`${hours.toString().padStart(2, "0")}:${minutes*/}
        {/*    .toString()*/}
        {/*    .padStart(2, "0")}:${seconds*/}
        {/*    .toString()*/}
        {/*    .padStart(2, "0")} to Launch`}*/}
        {/*  alignSelf="start"*/}
        {/*  size={sizeVariables.buttonSize[size]}*/}
        {/*  style={{*/}
        {/*    width: "250px",*/}
        {/*    cursor: "default",*/}
        {/*  }}*/}
        {/*/>*/}

        {/*<Link to="/airdrop" style={{ color: "white" }}>*/}
        {/*  <Text size={sizeVariables.ctaFontSize[size]}>*/}
        {/*    Check airdrop eligibility*/}
        {/*  </Text>*/}
        {/*</Link>*/}
      </Box>
    </Box>
  );
};

export default Home;
