import { Box } from "grommet";

const Wrapper = ({ children }) => {
  return (
    <Box round pad="large" background={{ color: "dark-3", opacity: "weak" }}>
      {children}
    </Box>
  );
};

export default Wrapper;
