import { Box, Spinner, Text } from "grommet";

import { Wrapper } from "..";

const TotalRaisedView = ({ totalSaleUSDC }) => {
  return (
    <Wrapper>
      {!totalSaleUSDC ? (
        <Spinner size="medium" color="white" alignSelf="center" />
      ) : (
        <Box gap="xsmall">
          <Text textAlign="center" size="large" weight={500}>
            Total Raised
          </Text>
          <Text textAlign="center" size="xlarge" weight={500}>
            $ {Number(totalSaleUSDC).toLocaleString()}
          </Text>
        </Box>
      )}
    </Wrapper>
  );
};

export default TotalRaisedView;
